import React, { Component } from 'react';
//import styled from 'styled-components';
import { graphql, Link } from 'gatsby';

import Layout from '../components/layouts';
import { Container } from '../components/styledComponents';
import { Title } from '../components/styledComponents/products';
import Seo from '../components/seo/page';
import { Wrapper, List, Service } from '../components/styledComponents/tabsStyled';

export default class Services extends Component {
  componentDidMount() {
    document.body.style.background = '#fff';
  }

  getData(nodes) {
    const out = {
      node: nodes[0].node,
      childrens: [],
    };

    out.childrens = nodes
      .filter((el, idx) => idx > 0)
      .map((el) => {
        return el.node;
      });

    return out;
  }

  render() {
    const { data } = this.props;
    const info = this.getData(data.allMarkdownRemark.edges);
    const node = info.node;

    return (
      <Layout>
        <Seo
          title={node.frontmatter.title}
          meta={{
            description: node.frontmatter.description,
            keywords: node.frontmatter.keywords,
            'og:url': 'solutions',
            'og:description': node.frontmatter.og_description,
          }}
        />
        <Container style={{ marginBottom: '70px' }}>
          <Title>{node.frontmatter.title}</Title>
          <Wrapper>
            <List>
              {info.childrens.map((el, index) => {
                const separateTitle = el.frontmatter.title.split('\\n');
                return (
                  <Service key={index}>
                    <Link to={'/services/' + el.frontmatter.block}>
                      {separateTitle[0]}
                      <br />
                      {separateTitle[1]}
                    </Link>
                  </Service>
                );
              })}
            </List>
          </Wrapper>
        </Container>
      </Layout>
    );
  }
}

export const query = graphql`
  query getServicesData {
    allMarkdownRemark(
      filter: { frontmatter: { page: { eq: "services" } } }
      sort: { fields: [frontmatter___index], order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            page
            section
            title
            index
            main
            block
            keywords
            description
            og_description
          }
          html
        }
      }
    }
  }
`;
